import {
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "lib/components";
import Box from "lib/components/Box";
import Accordion from "lib/components/Accordion";
import Typography from "lib/components/Typography";
import Paper from "lib/components/Paper";
import Card, {CardProps} from "lib/components/Card";

import Section from "app/components/Section";
import getColor from "app/components/common/getColor";
import getStatusIcon from "app/components/common/getStatusIcon";
import {ChargePointConnector} from "app/components/chargepoint/model/ChargePointConnector";
import DateUtils from "app/components/common/DateUtils";
import {ChargePointTransaction} from "app/components/chargepoint/model/ChargePointTransaction";
import {IconButton} from "@mui/material";
import {Stop} from "@mui/icons-material";
import OcppApiService from "app/services/OcppApiService";
import useErrorHandler from "app/hooks/useErrorHandler";
import {useState} from "react";

function getTransactionsCard(transactions?: ChargePointTransaction[]) {

    if (!transactions || transactions.length === 0) {
        return <TableBody>
            <TableRow
                sx={{
                    "&:last-child td, &:last-child th": {border: 0},
                }}>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
            </TableRow>
        </TableBody>
    }

    return <TableBody>
        {transactions.map((transaction) => TransactionTableRow(transaction))}
    </TableBody>
}

function TransactionTableRow(transaction: ChargePointTransaction) {

    const {onError} = useErrorHandler();
    const [loading, setLoading] = useState<boolean>(false);

    const stopTransaction = async (transactionId: number) => {
        try {
            setLoading(true);
            await OcppApiService.forceStopTransaction(transactionId);
        } catch (error) {
            onError(error, "Stop transaction failed: ");
        } finally {
            setLoading(false);
        }
    }

    return <TableRow
        key={transaction.startAt.toString()}
        sx={{
            "&:last-child td, &:last-child th": {border: 0},
        }}>
        <TableCell>{transaction.id}</TableCell>
        <TableCell>{transaction.idTag}</TableCell>
        <TableCell>{transaction.meterStart}</TableCell>
        <TableCell>{DateUtils.formatDate(transaction.startAt, "dd.MM.yyyy HH:mm:ss")}</TableCell>
        <TableCell>{transaction.meterStop}</TableCell>
        <TableCell>{DateUtils.formatDate(transaction.stopAt, "dd.MM.yyyy HH:mm:ss")}</TableCell>
        <TableCell>{transaction.reason}</TableCell>
        <TableCell>
            {transaction.stopAt ? null :
                <IconButton
                    size={"small"}
                    disabled={loading}
                    onClick={() => {
                        void stopTransaction(transaction.id)
                    }}>
                    <Stop></Stop>
                </IconButton>
            }
        </TableCell>
    </TableRow>
}

interface ConnectorProps {
    index: number
    connector: ChargePointConnector
}

const Connector = (props: ConnectorProps) => {
    const data = [
        [
            {
                key: "Error code",
                value: props.connector.errorCode,
            },
            {
                key: "Vendor error code",
                value: props.connector.vendorErrorCode,
            },
        ],
        [
            {
                key: "Updated at",
                value: DateUtils.formatDate(props.connector.updatedAt, "dd.MM.yyyy HH:mm:ss")
            },
            {
                key: "Created at",
                value: DateUtils.formatDate(props.connector.createdAt, "dd.MM.yyyy HH:mm:ss")
            },
        ],
    ];

    const cards = [
        {
            title: "Info",
            description: props.connector.info,
            icon: "Info",
            color: "ocean",
        },
        {
            title: "Meter Wh",
            description: props.connector.meterWh,
            icon: "Zap",
            color: "coral",
        },
        {
            title: "Vendor ID",
            description: props.connector.vendorId,
            icon: "Shield",
            color: "night",
        },
        {
            title: "Status",
            description: props.connector.status,
            color: getColor(props.connector.status),
            icon: getStatusIcon(props.connector.status),
        },
    ] as CardProps[];

    const gridItemProps = {
        xs: 12,
        md: 6,
        lg: 3,
    };

    return (
        <Accordion
            key={props.connector.connectorId}
            isElevated
            isDefaultOpen={props.index === 0}
            summary={
                <Box>
                    <Typography variant="body2">{props.connector.chargePointIdentity}</Typography>
                </Box>
            }
            details={
                <Stack direction="column" spacing="2rem" sx={{overflow: "hidden"}}>
                    <Box>
                        <Grid container spacing="2rem" flex={1}>
                            {cards.map(({title, description, color, icon}, index) => (
                                <Grid item {...gridItemProps} key={index}>
                                    <Card
                                        isElevated={false}
                                        title={title}
                                        description={description}
                                        color={color}
                                        icon={icon}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container spacing="2rem">
                            {data.map((rows, dataIndex) => (
                                <Grid item xs={12} md={6} xl={6} key={dataIndex}>
                                    <List>
                                        {rows.map(({key, value}, rowIndex) => (
                                            <ListItem
                                                key={key}
                                                divider={rowIndex + 1 !== rows.length}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <ListItemText sx={{textAlign: "left"}}>
                                                    {key}
                                                </ListItemText>
                                                <ListItemText sx={{textAlign: "right"}}>
                                                    {value}
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box>
                        <Section title="Transactions" icon="GitCommit"/>
                    </Box>
                    <Paper
                        sx={{
                            padding: 0,
                            borderRadius: "1rem",
                            overflow: "hidden",
                        }}
                    >
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "night.10",
                                        ".MuiTableCell-root": {
                                            fontWeight: 700,
                                            paddingY: "1rem",
                                            fontSize: "1.3rem",
                                        },
                                    }}>
                                    <TableCell>ID</TableCell>
                                    <TableCell>ID Tag</TableCell>
                                    <TableCell>Meter start</TableCell>
                                    <TableCell>Start at</TableCell>
                                    <TableCell>Meter stop</TableCell>
                                    <TableCell>Stop at</TableCell>
                                    <TableCell>Reason</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            {getTransactionsCard(props.connector.transactions)}
                        </Table>
                    </Paper>
                    <Box sx={{pt: "1rem"}}>
                        <Section title="Measurements" icon="Activity"/>
                    </Box>
                    <Paper
                        sx={{
                            padding: 0,
                            borderRadius: "1rem",
                            overflow: "hidden",
                        }}>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "night.10",
                                        ".MuiTableCell-root": {
                                            fontWeight: 700,
                                            paddingY: "1rem",
                                            fontSize: "1.3rem",
                                        },
                                    }}>
                                    <TableCell>Measurand</TableCell>
                                    <TableCell>Phase</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>Context</TableCell>
                                    <TableCell>Format</TableCell>
                                    <TableCell>Location</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.connector.measurements.length ? (
                                    props.connector.measurements.map(
                                        ({id, measurand, phase, value, unit, context, format, location}) => (
                                            <TableRow
                                                key={id}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {border: 0},
                                                }}>
                                                <TableCell>{measurand}</TableCell>
                                                <TableCell>{phase}</TableCell>
                                                <TableCell>{value}</TableCell>
                                                <TableCell>{unit}</TableCell>
                                                <TableCell>{context}</TableCell>
                                                <TableCell>{format}</TableCell>
                                                <TableCell>{location}</TableCell>
                                            </TableRow>
                                        )
                                    )
                                ) : (
                                    <TableRow
                                        sx={{
                                            "&:last-child td, &:last-child th": {border: 0},
                                        }}>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Stack>
            }
            sx={{
                "& .MuiAccordionSummary-root, .MuiAccordionDetails-root": {
                    paddingX: "3rem",
                },
                "& .MuiAccordionDetails-root": {
                    paddingBottom: "3rem",
                },
            }}
        />
    );
};

export default Connector;
