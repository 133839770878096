import {Container} from "lib/components";
import Typography from "lib/components/Typography";
import Box from "lib/components/Box";
import Logo from "app/components/Logo";
import {ReactNode} from "react";

export interface HeaderBreadcrumbs {
    label: string;
    onClick: () => void;
}

export interface HeaderProps {
    breadcrumbs?: HeaderBreadcrumbs[];
    children?: ReactNode;
}


const Header = ({children}: HeaderProps) => {
    return (
        <Box
            sx={{
                position: "fixed",
                zIndex: 2,
                width: "100%",
                minHeight: "9rem",
                top: 0,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
                borderColor: "night.40",
            }}>
            <Container
                maxWidth="xl"
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box
                        sx={{
                            width: "3.5rem",
                            height: "3.5rem",
                        }}>
                        <Logo/>
                    </Box>
                    <Box
                        sx={{
                            marginLeft: "2.4rem",
                            display: "flex",
                            flexDirection: "column",
                            lineHeight: 1,
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    lineHeight: "inherit",
                                }}>
                                OCPP 1.6 Toolkit
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {children}
            </Container>
        </Box>
    );
};

export default Header;
