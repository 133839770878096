import {Form, Formik, useFormikContext} from "formik";
import {useContext} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import useRouter from "app/hooks/useRouter";
import * as yup from "yup";
import {RemoteStartTransaction} from "app/components/action/model/Actions";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import {Stack} from "lib/components";
import TextField from "lib/components/TextField/TextField";
import Button from "lib/components/Button/Button";
import useErrorHandler from "app/hooks/useErrorHandler";
import OcppApiService from "app/services/OcppApiService";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import {ActionType} from "app/components/action/model/ActionType";

const InnerForm = () => {

    const actionContext = useContext(ActionContext);

    const {values, handleChange, isValid, errors} = useFormikContext<RemoteStartTransaction>();

    return <Form>
        <DialogHeader>Remote start transaction</DialogHeader>
        <DialogContent>
            <Stack direction="column" spacing="3rem">
                <TextField
                    label="ID tag"
                    fullWidth
                    id="idTag"
                    name="idTag"
                    value={values.idTag}
                    onChange={handleChange}
                    error={Boolean(errors.idTag)}
                    helperText={errors.idTag ? errors.idTag : ""}/>
                <TextField
                    label="Connector ID"
                    fullWidth
                    id="connectorId"
                    name="connectorId"
                    value={values.connectorId}
                    onChange={handleChange}
                    error={Boolean(errors.connectorId)}
                    helperText={errors.connectorId ? errors.connectorId : ""}/>
            </Stack>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
}
const RemoteStartTransactionForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: RemoteStartTransaction) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.RemoteStartTransaction, params.identity, request);
            await OcppApiService.remoteStartTransaction(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                connectorId: "1",
                idTag: "123456",
            }}
            onSubmit={handleSubmit}
            validationSchema={yup.object({
                connectorId: yup.string().required("Connector ID is a required field"),
                idTag: yup.string().required("ID tag is a required field"),
            })}>
            <InnerForm/>
        </Formik>
    );
};

export default RemoteStartTransactionForm;
