import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "app/app";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import * as Sentry from "@sentry/react";
import {Config} from "app/Config";

if (Config.sentryDsn) {
    Sentry.init({
        dsn: Config.sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 1.0,
        environment: Config.stage
    });
}

AnalyticsHelper.setup();

ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
