import format from "date-fns/format";

export default class DateUtils {

    // @ts-ignore
    static formatDateString(dateString?: string, dateFormat: string) {

        if (!dateString) {
            return "-"
        }

        const date = new Date(dateString)

        return format(date, dateFormat)
    }

    // @ts-ignore
    static formatDate(dateString?: Date, dateFormat: string) {

        if (!dateString) {
            return "-"
        }

        const date = new Date(dateString)

        return format(date, dateFormat)
    }
}