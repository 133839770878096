import Box from "lib/components/Box";
import Icon, {IconName} from "lib/components/Icon";
import Typography from "lib/components/Typography";

export interface SectionProps {
    title: string;
    icon: IconName;
}

const Section = ({title, icon}: SectionProps) => (
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
        }}>
        <Icon name={icon} size="medium"/>
        <Typography
            variant="subtitle1"
            sx={{
                marginLeft: "1rem",
            }}>
            {title}
        </Typography>
    </Box>
);

export default Section;
