import {Grid, ListItem, ListItemText, Typography} from "lib/components";
import Box from "lib/components/Box";
import React from "react";
import {ChargePoint} from "app/components/chargepoint/model/ChargePoint";
import DateUtils from "app/components/common/DateUtils";
import Paper from "lib/components/Paper";

interface AuthenticationInformationProps {
    chargePoint: ChargePoint
}

function ListItemGroup(props: {
    title: string,
    value?: string
    divider: boolean | undefined
}) {
    return <ListItem
        key={props.title}
        divider={props.divider ?? false}
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}>
        <ListItemText sx={{textAlign: "left"}}>
            {props.title}
        </ListItemText>
        <ListItemText sx={{textAlign: "right"}}>
            {props.value}
        </ListItemText>
    </ListItem>
}

const AuthenticationInformation = (props: AuthenticationInformationProps) => {
    return <Box sx={{marginTop: "2rem"}}>
        <Grid container spacing="2rem">
            <Grid item xs={12} md={12} xl={12}>
                <Paper>
                    <Typography>Authentication Status</Typography>
                    <ListItemGroup title="Password Set"
                                   value={props.chargePoint.chargePointAuthentication.passwordSet ? "Yes" : "No"}
                                   divider={true}/>
                    <ListItemGroup title="Password Reset Required?"
                                   value={props.chargePoint.chargePointAuthentication.passwordResetRequired ? "Yes" : "No"}
                                   divider={true}/>
                    <ListItemGroup title="Created at"
                                   value={DateUtils.formatDate(props.chargePoint.chargePointAuthentication.createdAt, "dd.MM.yyyy HH:mm:ss")}
                                   divider={true}/>
                    <ListItemGroup title="Updated at"
                                   value={DateUtils.formatDate(props.chargePoint.chargePointAuthentication.updatedAt, "dd.MM.yyyy HH:mm:ss")}
                                   divider={true}/>
                </Paper>
            </Grid>
        </Grid>
    </Box>
};

export default AuthenticationInformation;
