import {forwardRef, Ref} from 'react';
import Box from '@mui/material/Box';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogHeader from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogFooter from '@mui/material/DialogActions';

import Avatar from '../Avatar';
import Icon from '../Icon';
import Typography from '../Typography';

import {DialogProps} from './types';

const Dialog = (
    {
        children,
        fullScreen = false,
        fullWidth = true,
        onClose,
        isOpen,
        title,
        description,
        maxWidth = 'xs',
        icon,
        ...props
    }: DialogProps,
    ref: Ref<HTMLDivElement>
): JSX.Element => {
    const withDetails = !!icon || !!title || !!description;

    return (
        <MuiDialog
            ref={ref}
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            open={isOpen}
            onClose={onClose}
            maxWidth={maxWidth}
            sx={{
                ...(!withDetails && {
                    '.MuiDialog-paper': {
                        padding: 0,
                    },
                }),
            }}
            {...props}
        >
            <Box>
                {withDetails && (
                    <MuiDialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginBottom: '3.2rem',
                            padding: '0 !important',
                        }}
                    >
                        {icon && (
                            <Box sx={{marginBottom: '3.2rem'}}>
                                <Avatar>
                                    <Icon name={icon}/>
                                </Avatar>
                            </Box>
                        )}
                        {title && <Typography variant="h5">{title}</Typography>}
                        {description && (
                            <Typography
                                variant="body1"
                                sx={{marginTop: title ? '1rem' : '0'}}
                            >
                                {description}
                            </Typography>
                        )}
                    </MuiDialogContent>
                )}
            </Box>
            {children}
        </MuiDialog>
    );
};

export const DialogHeader = MuiDialogHeader;
export const DialogContent = MuiDialogContent;
export const DialogFooter = MuiDialogFooter;

export default forwardRef(Dialog);
