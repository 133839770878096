const getStatusIcon = (status: string) => {
    switch (status.toLocaleLowerCase()) {
        case "unavailable":
            return "XOctagon";
        case "suspendedev":
        case "suspendedevse":
        case "faulted":
        case "fail_safe":
            return "AlertCircle";
        case "preparing":
        case "finishing":
            return "RefreshCw";
        case "charging":
            return "Zap";
        case "operational":
            return "Zap";
        case "available":
            return "Zap";
        default:
            return "Zap";
    }
};

export default getStatusIcon;
