import Box from "lib/components/Box";

export interface TabPanelProps {
    children?: React.ReactNode;
    value: string;
    id: string;
}

const TabPanel = ({children, value, id}: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== id}
            id={`tabpanel-${id}`}
            aria-labelledby={`tab-${id}`}>
            {value === id && <Box sx={{marginTop: "3rem"}}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
