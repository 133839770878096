import Box from "lib/components/Box";

import {ChargePointConnector} from "app/components/chargepoint/model/ChargePointConnector";
import Connector from "app/components/chargepoint/Connector";

interface ConnectorsProps {
    connectors: ChargePointConnector[];
}

const Connectors = ({connectors}: ConnectorsProps) => {
    return <Box sx={{width: "100%", marginTop: "2rem"}}>
        {connectors.map((connector, index) => (
            <Connector key={connector.connectorId} index={index} connector={connector}/>
        ))}
    </Box>
};

export default Connectors;
