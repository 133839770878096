export default class StringUtils {
    static isBlankOrNull(value: string | null): boolean {
        if (value == null) {
            return true
        }
        return value.replaceAll(/\s/g, "").length === 0
    }

    static isEmail(value: string | null) {
        if (value == null) {
            return false
        }
        return RegExp("^\\w+([.-]?\\w+)*@\\w+([.-]?\\w+)*").test(value)
    }
}