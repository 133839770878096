import {Form, Formik, useFormikContext} from "formik";
import {ChangeEvent, useContext} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import useRouter from "app/hooks/useRouter";
import {TriggerMessage} from "app/components/action/model/Actions";
import * as yup from "yup";
import {TriggerMessageRequestType} from "app/components/action/model/TriggerMessageRequestType";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import {Stack} from "lib/components";
import Dropdown from "lib/components/Dropdown/Dropdown";
import TextField from "lib/components/TextField/TextField";
import Button from "lib/components/Button/Button";
import useErrorHandler from "app/hooks/useErrorHandler";
import OcppApiService from "app/services/OcppApiService";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import {ActionType} from "app/components/action/model/ActionType";

const messages = [
    {
        id: "BootNotification",
        value: "BootNotification",
    },
    {
        id: "DiagnosticsStatusNotification",
        value: "DiagnosticsStatusNotification",
    },
    {
        id: "FirmwareStatusNotification",
        value: "FirmwareStatusNotification",
    },
    {
        id: "Heartbeat",
        value: "Heartbeat",
    },
    {
        id: "MeterValues",
        value: "MeterValues",
    },
    {
        id: "StatusNotification",
        value: "StatusNotification",
    },
];

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const {values, handleChange, setFieldValue, isValid, errors} = useFormikContext<TriggerMessage>();

    const handleMessageChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value;
        setFieldValue("message", value);
    };

    return <Form>
        <DialogHeader>Trigger message</DialogHeader>
        <DialogContent>
            <Stack direction="column" spacing="3rem">
                <Dropdown
                    label="Message"
                    onChange={handleMessageChange}
                    options={messages}
                    value={values.message}
                />
                <TextField
                    label="Connector ID"
                    fullWidth
                    id="connectorId"
                    name="connectorId"
                    value={values.connectorId}
                    onChange={handleChange}
                    error={Boolean(errors.connectorId)}
                    helperText={errors.connectorId ? errors.connectorId : ""}
                />
            </Stack>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
};

const TriggerMessageForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: TriggerMessage) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.TriggerMessage, params.identity, request);
            await OcppApiService.triggerMessage(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                connectorId: "1",
                message: "Heartbeat" as TriggerMessageRequestType,
            }}
            onSubmit={handleSubmit}
            validationSchema={yup.object({
                connectorId: yup.string().required("Connector ID is a required field"),
            })}>
            <InnerForm/>
        </Formik>
    );
};

export default TriggerMessageForm;
