import MuiCard from '@mui/material/Card';
import MuiCardHeader from '@mui/material/CardHeader';

import boxShadows from '../../styles/boxShadows';

import {Box, useTheme} from '../index';

import Avatar from '../Avatar';
import Icon from '../Icon';

import {CardProps} from './types';

const Card = ({
                  children,
                  title,
                  description,
                  icon,
                  color,
                  onClick,
                  isSelected,
                  isElevated = true,
                  sx,
              }: CardProps) => {
    const isExtended = !!children;
    const isClickable = !!onClick;
    const withSelectorIcon = !isExtended && isClickable;
    const theme = useTheme();
    return (
        <MuiCard
            {...(onClick && {onClick})}
            sx={{
                ...(isExtended && {
                    padding: '3.2rem 2.4rem',
                }),
                ...(isElevated && {
                    boxShadow: boxShadows[1],
                }),
                ...(isSelected && {
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        top: '0',
                        bottom: '0',
                        zIndex: 0,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'primary.main',
                        pointerEvents: 'none',
                        borderRadius: '0.8rem',
                    },
                }),
                ...(isClickable && {
                    cursor: 'pointer',
                    '&:hover': {
                        boxShadow: '0px 8px 20px rgba(100, 100, 100, 0.1)',
                    },
                }),
                ...sx,
            }}
        >
            <MuiCardHeader
                action={
                    withSelectorIcon && (
                        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Icon name="ChevronRight" size="medium"/>
                        </Box>
                    )
                }
                avatar={
                    icon && (
                        <Avatar color={color} size="medium">
                            <Icon name={icon}/>
                        </Avatar>
                    )
                }
                title={title}
                subheader={description}
                sx={{
                    ...(isExtended && {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '.MuiCardHeader-avatar': {
                            marginRight: 0,
                            marginBottom: 'calc(2.4rem - 1rem)',
                        },
                    }),
                }}
            />
            {isExtended && (
                <Box sx={{marginTop: '1rem', textAlign: 'center'}}>{children}</Box>
            )}
        </MuiCard>
    );
};

export default Card;
