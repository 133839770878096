import {Stack} from "lib/components";
import Box from "lib/components/Box";
import Button from "lib/components/Button";
import Section from "app/components/Section";
import {useContext, useMemo} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import {ActionType} from "app/components/action/model/ActionType";
import useAuthentication from "app/hooks/useAuthentication";
import {Divider} from "@mui/material";

const Sidebar = () => {

    const actionContext = useContext(ActionContext);
    const authentication = useAuthentication()

    const authenticationActions = useMemo(() => [
            {
                name: "Remove password",
                onClick: () => authentication.removePassword()
            },
            {
                name: "Reset password",
                onClick: () => authentication.resetPassword()
            }
        ],
        [authentication?.removePassword, authentication?.resetPassword]
    )

    const actions = useMemo(() => [
            {
                name: "Reset",
                onClick: () => actionContext.updateAction(ActionType.Reset)
            },
            {
                name: "Unlock Connector",
                onClick: () => actionContext.updateAction(ActionType.UnlockConnector)
            },
            {
                name: "Data Transfer",
                onClick: () => actionContext.updateAction(ActionType.DataTransfer)
            },
            {
                name: "Remote Start Transaction",
                onClick: () => actionContext.updateAction(ActionType.RemoteStartTransaction)
            },
            {
                name: "Remote Stop Transaction",
                onClick: () => actionContext.updateAction(ActionType.RemoteStopTransaction)
            },
            {
                name: "Trigger Message",
                onClick: () => actionContext.updateAction(ActionType.TriggerMessage)
            },
            {
                name: "Send Local List",
                onClick: () => actionContext.updateAction(ActionType.SendLocalList)
            },
            {
                name: "Set Charging Profile",
                onClick: () => actionContext.updateAction(ActionType.SetChargingProfile)
            },
            {
                name: "Clear Charging Profile",
                onClick: () => actionContext.updateAction(ActionType.ClearChargingProfile)
            },
            {
                name: "Get Composite Schedule",
                onClick: () => actionContext.updateAction(ActionType.GetCompositeSchedule)
            },
            {
                name: "Get Configuration",
                onClick: () => actionContext.updateAction(ActionType.GetConfiguration)
            },
            {
                name: "Get Diagnostics",
                onClick: () => actionContext.updateAction(ActionType.GetDiagnostics)
            },
            {
                name: "Change configuration",
                onClick: () => actionContext.updateAction(ActionType.ChangeConfiguration)
            },
            {
                name: "Update firmware",
                onClick: () => actionContext.updateAction(ActionType.UpdateFirmware)
            },
            {
                name: "Set Heartbeat Interval",
                onClick: () => actionContext.updateAction(ActionType.SetHeartbeatInterval)
            },
            {
                name: "Disconnect",
                onClick: () => actionContext.updateAction(ActionType.Disconnect)
            }
        ],
        []
    );

    return <Box
        sx={{
            width: "60ch",
            height: "inherit",
            padding: "4rem",
            borderLeft: "1px solid",
            borderColor: "night.40"
        }}>
        <Section title="Authentication" icon="Lock"/>
        <Stack direction="column" spacing="1.4rem" sx={{marginTop: "3rem"}}>
            {authenticationActions.map(({name, onClick}) => (
                <Button key={name} variant="secondary" onClick={onClick}>
                    {name}
                </Button>
            ))}
        </Stack>
        <Divider sx={{marginTop: "3rem"}}/>
        <Box sx={{marginTop: "3rem"}}/>
        <Section title="Commands" icon="Command"/>
        <Stack direction="column" spacing="1.4rem" sx={{marginTop: "3rem"}}>
            {actions.map(({name, onClick}) => (
                <Button key={name} variant="secondary" onClick={onClick}>
                    {name}
                </Button>
            ))}
        </Stack>
    </Box>
};

export default Sidebar;
