import * as React from "react";
import {ChargePointLogEntry} from "./ChargePointLogEntry";
import {Config} from "app/Config";

type LogContextValue = {
    logs: ChargePointLogEntry[];
    setLogs: React.Dispatch<React.SetStateAction<ChargePointLogEntry[]>>;
    parseJsonContext: (log: ChargePointLogEntry) => any;
    findRelated: (log: ChargePointLogEntry) => ChargePointLogEntry[];
}

const LogsContext = React.createContext<LogContextValue | undefined>(undefined);
const LOG_MAX_LENGTH = 2000;

function LogsProvider({ children, identity }: React.PropsWithChildren<{ identity: string }>) {
    const [chargePointLogs, setChargePointLogs] = React.useState<ChargePointLogEntry[]>([])

    const wsRef = React.useRef<WebSocket>();
    const wsShutdown = React.useRef<boolean>(false);

    const parsedJsonContext = React.useRef(new WeakMap<ChargePointLogEntry, any>())

    const parseJsonContext = React.useCallback((log: ChargePointLogEntry): any => {
        const parsedJsonContextMap = parsedJsonContext.current;
        if (parsedJsonContextMap.has(log)) {
            return parsedJsonContextMap.get(log)
        }
        if (!log.context) {
            return null
        }
        const data = JSON.parse(log.context.json);
        parsedJsonContextMap.set(log, data)
        return data
    }, [])

    const findRelated = React.useCallback((log: ChargePointLogEntry): ChargePointLogEntry[] => {
        function isOcppArray(data: any): data is [number, string] {
            return data != null && typeof data[0] === "number" && typeof data[1] === "string"
        }
        const jsonData = parseJsonContext(log)
        if (!isOcppArray(jsonData)) {
            return []
        }
        return chargePointLogs.filter((otherLog) => {
            const otherJsonData = parseJsonContext(otherLog)
            if (!isOcppArray(otherJsonData)) {
                return false
            }
            return otherJsonData[1] === jsonData[1] && otherLog != log
        })
    }, [chargePointLogs])

    const connect = React.useCallback((identity: string) => {
        const connectAttempt = () => {
            if (wsRef.current) {
                return
            }

            const websocket = new WebSocket(Config.baseWssUrl + "/api/charge-point/" + identity + "/logs");

            wsRef.current = websocket;

            // Function to send heartbeat message
            const sendHeartbeat = () => {
                if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                    wsRef.current.send(JSON.stringify({ type: "heartbeat" }));
                }
            };

            // Send heartbeat message every X milliseconds
            const heartbeatInterval = setInterval(sendHeartbeat, 10000);


            websocket.onmessage = (event: MessageEvent) => {
                const data = JSON.parse(event.data) as ChargePointLogEntry;
                setChargePointLogs(chargePointLogs => [data, ...chargePointLogs,].slice(0, LOG_MAX_LENGTH))
            };

            websocket.onclose = () => {
                console.log("websocket disconnected")
                wsRef.current = undefined;
                clearInterval(heartbeatInterval); // Clear the heartbeat interval on close
                if (!wsShutdown.current) {
                    connectAttempt()
                }
            };
        }
        connectAttempt()
    }, [setChargePointLogs])

    React.useEffect(() => {
        connect(identity);
        return () => {
            if (wsRef.current) {
                if (wsRef.current?.readyState === 1) {
                    wsShutdown.current = true;
                    wsRef.current.close();
                    wsShutdown.current = false;
                }
            }
            setChargePointLogs([])
        };
    }, [identity]);

    const value: LogContextValue = React.useMemo(() => ({
        logs: chargePointLogs,
        setLogs: setChargePointLogs,
        parseJsonContext: parseJsonContext,
        findRelated: findRelated,
    }), [chargePointLogs, setChargePointLogs, parseJsonContext])

    return (
        <LogsContext.Provider value={value}>
            {children}
        </LogsContext.Provider>
    );
}

function useLogsContext(): LogContextValue {
    const context = React.useContext(LogsContext);
    if (context === undefined) {
        throw new Error('useLogsContext must be used within a LogsProvider');
    }
    return context;
}

function useLogs(): LogContextValue["logs"] {
    const logsContext = useLogsContext();
    return logsContext.logs;
}

function useLogParser(): LogContextValue["parseJsonContext"] {
    const logsContext = useLogsContext();
    return logsContext.parseJsonContext;
}

function useFindRelatedLog(): LogContextValue["findRelated"] {
    const logsContext = useLogsContext();
    return logsContext.findRelated;
}

export { LogsProvider, useLogs, useLogParser, useFindRelatedLog };