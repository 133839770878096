import {MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps,} from '@mui/material';
import {forwardRef, Ref} from 'react';

export interface MenuItemProps extends Omit<MuiMenuItemProps, 'onSelect'> {
    id?: string;
    button?: true;
    handleSelect?: (clickedItemId: string) => void;
    handleClose?: () => void;
}

const MenuItem = (
    {sx = {}, handleSelect, handleClose, id, children, ...props}: MenuItemProps,
    ref: Ref<HTMLLIElement>
): JSX.Element => {
    const handleClick = () => {
        if (handleSelect && id) {
            handleSelect(id);
        }
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <MuiMenuItem
            ref={ref}
            onClick={handleClick}
            sx={{
                '&.MuiMenuItem-root': {
                    height: '4rem !important',
                    display: 'flex !important',
                    alignItems: 'center',
                },
                '&.Mui-selected': {
                    backgroundColor: 'rgba(4, 91, 86, 0.08)',
                    '&:hover': {
                        backgroundColor: 'rgba(4, 91, 86, 0.1)',
                    },
                },
                ...sx,
            }}
            {...props}
        >
            {children}
        </MuiMenuItem>
    );
};

export default forwardRef(MenuItem);
