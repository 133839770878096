import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

function PageNotFoundPage() {
    return <Grid2 container spacing={0} component="main" sx={{height: '100vh'}}>
        <Grid2
            xs={4}
            sx={{
                backgroundImage: "url(/monta-universe.png)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        />
        <Grid2 xs={8} display="flex" justifyContent="center" alignItems="center">
            <Box sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Stack>
                    <Typography variant="h1" textAlign={"center"}>
                        Page not found
                    </Typography>
                    <Typography variant="body2" textAlign={"center"}>
                        <br/> We can't seem to find the page you are looking for
                    </Typography>
                </Stack>
            </Box>

        </Grid2>
    </Grid2>
}

export default PageNotFoundPage;
