import {Grid} from "lib/components";

import Card, {CardProps} from "lib/components/Card";
import getColor from "app/components/common/getColor";
import getStatusIcon from "app/components/common/getStatusIcon";

import {ChargePoint} from "app/components/chargepoint/model/ChargePoint";

interface BasicInformationProps {
    chargePointVendor: ChargePoint["chargePointVendor"];
    chargePointModel: ChargePoint["chargePointModel"];
    chargePointSerialNumber: ChargePoint["chargePointSerialNumber"];
    status: ChargePoint["status"];
}

const BasicInformation = (
    {
        chargePointVendor = "",
        chargePointModel = "",
        chargePointSerialNumber = "",
        status,
    }: BasicInformationProps) => {
    const gridItemProps = {
        xs: 12,
        md: 6,
        lg: 3,
    };

    const cards = [
        {
            title: "Vendor",
            description: chargePointVendor,
            icon: "Tool",
            color: "ocean",
        },
        {
            title: "Model",
            description: chargePointModel,
            icon: "Speaker",
            color: "ocean",
        },
        {
            title: "Serial number",
            description: chargePointSerialNumber,
            icon: "Key",
            color: "ocean",
        },
        {
            title: "Status",
            description: status,
            color: getColor(status),
            icon: getStatusIcon(status),
        },
    ] as CardProps[];

    return (
        <Grid container spacing="2rem" flex={1}>
            {cards.map(({title, description, color, icon}, index) => (
                <Grid item {...gridItemProps} key={index}>
                    <Card
                        title={title}
                        description={description}
                        color={color}
                        icon={icon}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default BasicInformation;
