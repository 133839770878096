import {Form, Formik, useFormikContext} from "formik";
import {ChangeEvent, useContext} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import useRouter from "app/hooks/useRouter";
import {ClearChargingProfile} from "app/components/action/model/Actions";
import {ChargingProfilePurposeType} from "app/components/action/model/ChargingProfilePurposeType";
import * as yup from "yup";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import {Stack} from "lib/components";
import Dropdown from "lib/components/Dropdown/Dropdown";
import TextField from "lib/components/TextField/TextField";
import Button from "lib/components/Button/Button";
import useErrorHandler from "app/hooks/useErrorHandler";
import OcppApiService from "app/services/OcppApiService";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import {ActionType} from "app/components/action/model/ActionType";

const chargingProfilePurposeTypes = [
    {
        id: "ChargePointMaxProfile",
        value: "ChargePointMaxProfile",
    },
    {
        id: "TxDefaultProfile",
        value: "TxDefaultProfile",
    },
    {
        id: "TxProfile",
        value: "TxProfile",
    },
];

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const {values, handleChange, setFieldValue, isValid, errors} = useFormikContext<ClearChargingProfile>();

    const handleChargingProfilePurposeTypeChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value;
        setFieldValue("chargingProfilePurposeType", value);
    };

    return <Form>
        <DialogHeader>Clear charging profile</DialogHeader>
        <DialogContent>
            <Stack direction="column" spacing="3rem">
                <Dropdown
                    label="Message"
                    onChange={handleChargingProfilePurposeTypeChange}
                    options={chargingProfilePurposeTypes}
                    value={values.chargingProfilePurposeType}
                />
                <TextField
                    label="Connector ID"
                    fullWidth
                    id="connectorId"
                    name="connectorId"
                    value={values.connectorId}
                    onChange={handleChange}
                    error={Boolean(errors.connectorId)}
                    helperText={errors.connectorId ? errors.connectorId : ""}
                />
                <TextField
                    label="Charging profile ID"
                    fullWidth
                    id="profileId"
                    name="profileId"
                    value={values.profileId}
                    onChange={handleChange}
                    error={Boolean(errors.profileId)}
                    helperText={errors.profileId ? errors.profileId : ""}
                />
                <TextField
                    label="Stack level"
                    fullWidth
                    id="stackLevel"
                    name="stackLevel"
                    value={values.stackLevel}
                    onChange={handleChange}
                    error={Boolean(errors.stackLevel)}
                    helperText={errors.stackLevel ? errors.stackLevel : ""}
                />
            </Stack>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
};

const ClearChargingProfileForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: ClearChargingProfile) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.ClearChargingProfile, params.identity, request);
            await OcppApiService.clearChargingProfile(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                chargingProfilePurposeType: ChargingProfilePurposeType.ChargePointMaxProfile,
                profileId: undefined,
                stackLevel: 0,
                connectorId: undefined,
            }}
            validationSchema={yup.object({
                profileId: yup.string().required("Charging profile ID is a required field"),
                stackLevel: yup.string().required("Stack level is a required field"),
            })}
            onSubmit={handleSubmit}>
            <InnerForm/>
        </Formik>
    );
};

export default ClearChargingProfileForm;
