import {identify, Identify, init, setDeviceId, setUserId, track, Types} from "@amplitude/marketing-analytics-browser";
import {v4 as uuidv4} from "uuid";
import {Config} from "app/Config";
import StringUtils from "app/components/common/StringUtils";

export default class AnalyticsHelper {

    private static deviceIdKey = "device_id"
    private static companyNameKey = "company_name"
    private static contactEmailKey = "contact_email"
    private static isEnabled = false

    static setup() {

        console.log("AnalyticsHelper setup", Config.amplitudeEnable)

        this.isEnabled = Config.amplitudeEnable

        if (!this.isEnabled) {
            console.log("Analytics disabled")
            return
        }

        // Setup Framework
        init(Config.amplitudeKey, undefined, {
            pageViewTracking: {
                trackHistoryChanges: "all"
            },
            serverZone: Types.ServerZone.EU,
        });

        // Set DeviceId
        setDeviceId(AnalyticsHelper.getUniqueIdentifier())

        // Set company details if previously set
        this.setCompanyDetails(
            this.getCompanyName(),
            this.getContactEmail()
        )
    }

    static setCompanyDetails(
        companyName: string | null,
        contactEmail: string | null
    ) {

        if (!this.isEnabled) {
            console.log("Analytics disabled")
            return
        }

        const identifyObj = new Identify();
        identifyObj.set("company_name", companyName ?? "");
        identifyObj.set("email", contactEmail ?? "");
        identify(identifyObj);

        this.setCompanyName(companyName);
        this.setContactEmail(contactEmail);
    }

    static trackCommandStart(
        ocppCommandType: string,
        chargePointIdentity: string,
        payload: any | null = null
    ) {
        if (!this.isEnabled) return
        track("Button Clicked", {
            "type": "command",
            "charge_point_identity": chargePointIdentity,
            "command_name": ocppCommandType,
            "payload": payload
        });
    }

    static trackChargePointViewed(
        chargePointIdentity: string
    ) {
        console.log("trackChargePointViewed", chargePointIdentity);
        if (!this.isEnabled) return
        track("Charge Point Test Started", {
            "charge_point_identity": chargePointIdentity
        })
    }

    static trackTestStart(
        chargePointIdentity: string,
        serial: string | undefined,
        brand: string | undefined,
        model: string | undefined,
        firmwareVersion: string | undefined,
    ) {
        console.log("trackTestStart", chargePointIdentity);
        if (!this.isEnabled) return
        track("Charge Point Connected", {
            "charge_point_identity": chargePointIdentity,
            "charge_point_serial": serial,
            "charge_point_brand": brand,
            "charge_point_model": model,
            "charge_point_firmware": firmwareVersion
        })
    }

    static hasDetails(): boolean {
        if (!this.isEnabled) {
            console.log("Analytics disabled")
            return true
        }
        return !StringUtils.isBlankOrNull(
            this.getCompanyName()
        ) && !StringUtils.isBlankOrNull(
            this.getContactEmail()
        )
    }

    static getCompanyName(): string | null {
        return window.localStorage.getItem(this.companyNameKey)
    }

    private static getUniqueIdentifier(): string {
        const uuid = window.localStorage.getItem(this.deviceIdKey);
        if (!uuid) {
            const newUuid = uuidv4();
            window.localStorage.setItem(this.deviceIdKey, newUuid.toString());
            return newUuid
        }
        return uuid
    }

    private static setCompanyName(companyName: string | null) {
        this.setLocalStorage(this.companyNameKey, companyName)
    }

    private static setContactEmail(contactEmail: string | null) {
        setUserId(contactEmail ?? undefined);
        this.setLocalStorage(this.contactEmailKey, contactEmail)
    }

    private static getContactEmail(): string | null {
        return window.localStorage.getItem(this.contactEmailKey)
    }

    private static setLocalStorage(key: string, value: string | null) {
        if (value) {
            window.localStorage.setItem(key, value)
        } else {
            window.localStorage.removeItem(key)
        }
    }
}