import {motion} from "framer-motion";

const letterSpring = {
    type: "spring",
    damping: 3,
};

const dotSpring = {
    type: "spring",
    damping: 1,
};

export interface LogoProps {
    isAnimating?: boolean;
}

const Logo = ({isAnimating = false}: LogoProps) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible">
            <motion.path
                d="M16 29.5C15.0359 29.5 14.1474 28.9809 13.677 28.1428L5.3343 13.3298V26.8349C5.3343 28.3052 4.14083 29.4971 2.6686 29.4971C1.19637 29.4971 0 28.3081 0 26.8349V3.16549C0 1.95331 0.818875 0.894825 1.99201 0.58743C3.16515 0.280034 4.39637 0.802026 4.99165 1.85761L16 21.4033L27.0083 1.85761C27.6036 0.802026 28.8348 0.280034 30.008 0.58743C31.1811 0.894825 32 1.95331 32 3.16259V18.6977C32 20.1679 30.8065 21.3598 29.3343 21.3598C27.8621 21.3598 26.6686 20.1679 26.6686 18.6977V13.3298L18.326 28.1399C17.8526 28.978 16.9641 29.4971 16.0029 29.4971L16 29.5Z"
                fill="#FF5252"
                {...(isAnimating && {
                    initial: {
                        y: "-10%",
                    },
                    animate: {
                        y: "0%",
                    },
                    transition: letterSpring,
                })}
            />
            <motion.path
                d="M29.3357 29.4984C28.6326 29.4984 27.9441 29.2143 27.4501 28.7186C26.9533 28.2257 26.6686 27.5386 26.6686 26.8371C26.6686 26.6631 26.686 26.4892 26.7209 26.3152C26.7528 26.1471 26.8051 25.9789 26.872 25.8195C26.9388 25.66 27.0201 25.5035 27.1189 25.3614C27.2177 25.2136 27.3252 25.0773 27.4501 24.9555C27.5722 24.8309 27.7087 24.7207 27.854 24.625C27.9993 24.5265 28.1532 24.4482 28.3159 24.3786C28.4757 24.3148 28.6443 24.2598 28.8128 24.2279C29.1556 24.1554 29.513 24.1554 29.8558 24.2279C30.0243 24.2598 30.1928 24.3148 30.3526 24.3786C30.5153 24.4482 30.6693 24.5265 30.8146 24.625C30.9599 24.7236 31.0964 24.8309 31.2213 24.9555C31.3463 25.0802 31.4567 25.2136 31.5496 25.3614C31.6484 25.5035 31.7298 25.66 31.7966 25.8195C31.8634 25.9789 31.9157 26.1471 31.9477 26.3152C31.9826 26.4863 32 26.6602 32 26.8371C32 27.5386 31.7153 28.2228 31.2213 28.7186C31.0964 28.8432 30.9599 28.9563 30.8146 29.0491C30.6693 29.1477 30.5153 29.2288 30.3526 29.2955C30.1928 29.3651 30.0243 29.4144 29.8558 29.4463C29.6844 29.4811 29.5101 29.4984 29.3328 29.4984H29.3357Z"
                fill="#FF5252"
                {...(isAnimating && {
                    initial: {
                        y: "-10%",
                    },
                    animate: {
                        y: "0%",
                    },
                    transition: dotSpring,
                })}
            />
        </svg>
    );
};

export default Logo;
