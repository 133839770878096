import {Form, Formik, useFormikContext} from "formik";
import React, {useContext} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import useRouter from "app/hooks/useRouter";
import {GetDiagnosticsRequest} from "app/components/action/model/Actions";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import {Stack} from "lib/components";
import Button from "lib/components/Button/Button";
import useErrorHandler from "app/hooks/useErrorHandler";
import OcppApiService from "app/services/OcppApiService";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import {ActionType} from "app/components/action/model/ActionType";
import TextField from "lib/components/TextField/TextField";
import * as yup from "yup";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const {values, handleChange, isValid, errors, setFieldValue} = useFormikContext<GetDiagnosticsRequest>();

    return <Form>
        <DialogHeader>Unlock Connector</DialogHeader>
        <DialogContent>
            <Stack direction="column" spacing="3rem">
                <TextField
                    label="Location (URL)"
                    fullWidth
                    id="location"
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    error={Boolean(errors.location)}
                    helperText={errors.location ? errors.location : ""}/>
                <TextField
                    label="Retries"
                    fullWidth
                    id="retries"
                    name="retries"
                    type={"number"}
                    value={values.retries}
                    onChange={handleChange}
                    error={Boolean(errors.retries)}
                    helperText={errors.retries ? errors.retries : ""}/>
                <TextField
                    label="Retry Interval"
                    fullWidth
                    id="retryInterval"
                    name="retryInterval"
                    type={"number"}
                    value={values.retryInterval}
                    onChange={handleChange}
                    error={Boolean(errors.retryInterval)}
                    helperText={errors.retryInterval ? errors.retryInterval : ""}/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker label={"Start Time"}
                                    value={values.startTime ? dayjs(values.startTime) : undefined}
                                    onChange={(value: Dayjs | null, _) => {
                                        setFieldValue("startTime", value?.toISOString());
                                    }}/>
                    <DateTimePicker label={"End Time"}
                                    value={values.stopTime ? dayjs(values.stopTime) : undefined}
                                    onChange={(value: Dayjs | null, _) => {
                                        setFieldValue("stopTime", value?.toISOString());
                                    }}/>
                </LocalizationProvider>
            </Stack>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
};

const GetDiagnosticsForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: GetDiagnosticsRequest) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.GetDiagnostics, params.identity, request);
            await OcppApiService.getDiagnostics(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                location: "",
                retries: undefined,
                retryInterval: undefined,
                startTime: undefined,
                stopTime: undefined,
            }}
            validationSchema={yup.object({
                location: yup.string().required().url(),
                retries: yup.number().optional().min(0).integer(),
                retryInterval: yup.number().optional().min(0).integer(),
                startTime: yup.date().optional(),
                stopTime: yup.date().optional()
            })}
            onSubmit={handleSubmit}>
            <InnerForm/>
        </Formik>
    );
};

export default GetDiagnosticsForm;
