import Dialog from "lib/components/Dialog";
import RemoteStopTransactionForm from "app/components/action/forms/RemoteStopTransactionForm";
import RemoteStartTransactionForm from "app/components/action/forms/RemoteStartTransactionForm";
import ChangeConfigurationForm from "app/components/action/forms/ChangeConfigurationForm";
import ResetForm from "app/components/action/forms/ResetForm";
import TriggerMessageForm from "app/components/action/forms/TriggerMessageForm";
import SendLocalListForm from "app/components/action/forms/SendLocalListForm";
import UpdateFirmwareForm from "app/components/action/forms/UpdateFirmwareForm";
import DisconnectForm from "app/components/action/forms/DisconnectForm";
import SetChargingProfileForm from "app/components/action/forms/SetChargingProfileForm";
import ClearChargingProfileForm from "app/components/action/forms/ClearChargingProfileForm";
import GetCompositeScheduleForm from "app/components/action/forms/GetCompositeScheduleForm";
import GetConfigurationForm from "app/components/action/forms/GetConfigurationForm";
import {useContext} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import {ActionType} from "app/components/action/model/ActionType";
import DataTransferForm from "app/components/action/forms/DataTransferForm";
import SetHeartbeatIntervalForm from "app/components/action/forms/SetHeartbeatIntervalForm";
import GetDiagnosticsForm from "app/components/action/forms/GetDiagnosticsForm";
import UnlockConnectorForm from "app/components/action/forms/UnlockConnectorForm";

function Modal(props: { action?: ActionType }) {
    switch (props.action) {
        case ActionType.Reset:
            return <ResetForm/>;
        case  ActionType.UnlockConnector:
            return <UnlockConnectorForm/>;
        case ActionType.DataTransfer:
            return <DataTransferForm/>;
        case  ActionType.RemoteStartTransaction:
            return <RemoteStartTransactionForm/>;
        case  ActionType.RemoteStopTransaction:
            return <RemoteStopTransactionForm/>;
        case  ActionType.TriggerMessage:
            return <TriggerMessageForm/>;
        case  ActionType.SendLocalList:
            return <SendLocalListForm/>;
        case  ActionType.SetChargingProfile:
            return <SetChargingProfileForm/>;
        case  ActionType.ClearChargingProfile:
            return <ClearChargingProfileForm/>;
        case  ActionType.GetCompositeSchedule:
            return <GetCompositeScheduleForm/>;
        case  ActionType.GetConfiguration:
            return <GetConfigurationForm/>;
        case  ActionType.GetDiagnostics:
            return <GetDiagnosticsForm/>;
        case  ActionType.ChangeConfiguration:
            return <ChangeConfigurationForm/>;
        case  ActionType.UpdateFirmware:
            return <UpdateFirmwareForm/>;
        case  ActionType.SetHeartbeatInterval:
            return <SetHeartbeatIntervalForm/>;
        case  ActionType.Disconnect:
            return <DisconnectForm/>;
        default:
            return null;
    }
}

export default function ActionModal() {
    const actionContext = useContext(ActionContext);
    return <Dialog isOpen={actionContext.action != undefined}
                   onClose={actionContext.onModalClose}>
        <Modal action={actionContext.action}/>
    </Dialog>
};
