import {Form, Formik, useFormikContext} from "formik";
import {useContext} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import useRouter from "app/hooks/useRouter";
import {UpdateFirmware} from "app/components/action/model/Actions";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import {Stack} from "lib/components";
import TextField from "lib/components/TextField/TextField";
import Button from "lib/components/Button/Button";
import * as yup from "yup";
import useErrorHandler from "app/hooks/useErrorHandler";
import OcppApiService from "app/services/OcppApiService";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import {ActionType} from "app/components/action/model/ActionType";

const InnerForm = () => {

    const actionContext = useContext(ActionContext);
    const {values, handleChange, isValid, errors} = useFormikContext<UpdateFirmware>();

    return <Form>
        <DialogHeader>Update Firmware</DialogHeader>
        <DialogContent>
            <Stack direction="column" spacing="3rem">
                <TextField
                    label="Location"
                    fullWidth
                    id="location"
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    error={Boolean(errors.location)}
                    helperText={errors.location ? errors.location : ""}/>
                <TextField
                    label="Retries"
                    fullWidth
                    id="retries"
                    name="retries"
                    value={values.retries}
                    onChange={handleChange}
                    error={Boolean(errors.retries)}
                    helperText={errors.retries ? errors.retries : ""}/>
                <TextField
                    label="Retrieve date"
                    fullWidth
                    id="retrieveDate"
                    name="retrieveDate"
                    value={values.retrieveDate}
                    onChange={handleChange}
                    error={Boolean(errors.retrieveDate)}
                    helperText={errors.retrieveDate ? errors.retrieveDate : ""}/>
                <TextField
                    label="Retry interval"
                    fullWidth
                    id="retryInterval"
                    name="retryInterval"
                    value={values.retryInterval}
                    onChange={handleChange}
                    error={Boolean(errors.retryInterval)}
                    helperText={errors.retryInterval ? errors.retryInterval : ""}/>
            </Stack>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
};

const UpdateFirmwareForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: UpdateFirmware) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.UpdateFirmware, params.identity, request);
            await OcppApiService.updateFirmware(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                location: "ftp://firmware.monta.app",
                retries: "",
                retrieveDate: new Date().toISOString(),
                retryInterval: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={yup.object({
                location: yup.string().required("Location is a required field")
            })}>
            <InnerForm/>
        </Formik>
    );
};

export default UpdateFirmwareForm;
