import MuiChip from '@mui/material/Chip';

import {LabelProps} from './types';
import getColor from "lib/styles/getColor";
import getBackgroundColor from "lib/styles/getBackgroundColor";

const Label = ({text, color = 'ocean', isInverted = false, icon,}: LabelProps) => {
    return (
        <MuiChip
            icon={icon}
            sx={{
                color: getColor(color, isInverted),
                height: 'auto',
                backgroundColor: getBackgroundColor(color, isInverted),
                span: {
                    padding: '0.4rem 1.2rem',
                    fontSize: '1.2rem',
                    fontWeight: 700,
                },
            }}
            label={text}
        />
    );
};

export default Label;
