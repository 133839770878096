const getColor = (status: string) => {
    switch (status.toLocaleLowerCase()) {
        case "unavailable":
            return "sand";
        case "suspendedev":
        case "suspendedevse":
        case "faulted":
        case "fail_safe":
            return "red";
        case "preparing":
        case "finishing":
            return "yellow";
        case "charging":
            return "ocean";
        case "operational":
            return "green";
        case "available":
            return "green";
        default:
            return "night";
    }
};

export default getColor;
