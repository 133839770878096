import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import Label from "lib/components/Label";
import {Color} from "lib/types/color";
import * as React from "react";
import {ChargePointLogEntry} from "app/components/log/ChargePointLogEntry";
import Typography from "lib/components/Typography";
import DateUtils from "app/components/common/DateUtils";
import {colors, IconButton, Tooltip} from "@mui/material";
import {Download} from "@mui/icons-material";
import {useFindRelatedLog, useLogParser, useLogs} from "./LogsContext";

const getColor = (level?: string): Color => {
    switch (level) {
        case "Info":
            return "ocean";
        case "Trace":
            return "green";
        default:
            return "sand";
    }
};

const columns: GridColDef[] = [
    {
        flex: 1,
        field: "timestamp",
        headerName: "Timestamp",
        headerAlign: "center",
        align: "center",
        renderCell: (params: GridRenderCellParams<string>) => (
            params.value ?
                <Typography variant="caption">{DateUtils.formatDateString(params.value, "HH:mm:ss")}</Typography> : null
        )
    },
    {
        flex: 1,
        field: "level",
        headerName: "Level",
        headerAlign: "center",
        align: "center",
        renderCell: (params: GridRenderCellParams<string>) => (
            <Label text={params.value ?? "unknown"} color={getColor(params.value)} />
        )
    },
    {
        flex: 6,
        field: "message",
        headerName: "Message",
    }
];

const Logs = () => {
    const escapeString = React.useCallback((value: string | undefined | null): string | undefined => {
        return value?.replaceAll("\n", "")?.replaceAll("\"", "\"\"")
    }, [])

    const logToCsvLine = React.useCallback((log: ChargePointLogEntry): string => {
        return "\"" + escapeString(log.timestamp) + "\",\"" + escapeString(log.message) + "\"\n"
    }, [escapeString])

    const chargePointLogs = useLogs();
    const logParser = useLogParser();
    const findRelatedLog = useFindRelatedLog();

    const sortedLogs = React.useMemo(() => {
        return [...chargePointLogs].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    }, [chargePointLogs]);

    const onExportButtonClicked = React.useCallback(async () => {

        // Create CSV Header
        let data = "timestamp,logMessage\n";

        for (let log of sortedLogs) {
            data += logToCsvLine(log)
        }

        const blob = new Blob([data], {
            type: 'text/csv'
        })

        const file = window.URL.createObjectURL(blob);

        window.location.assign(file);

    }, [logToCsvLine, sortedLogs])

    const getRowId = React.useCallback((row: ChargePointLogEntry) => {
        const data = (() => {
            try {
                const jsonData = logParser(row)
                if (jsonData && typeof jsonData[0] === "number" && typeof jsonData[1] === "string") {
                    return [jsonData[0].toString(), jsonData[1]]
                } else {
                    return [row.timestamp, row.message]
                }
            } catch (e) {
                return [row.timestamp, row.message]
            }
        })()
        return data.join("_")
    }, [logParser])

    const [relatedRows, setRelatedRows] = React.useState<ChargePointLogEntry[]>([])

    const relatedRowCSS = React.useMemo(() => {
        return Object.fromEntries(
            relatedRows.map((rel) => {
                return [
                    `& .MuiDataGrid-row[data-id="${getRowId(rel)}"]`,
                    {
                        backgroundColor: colors.blueGrey[50],
                    },
                ]
            }))
    }, [relatedRows, getRowId])

    return <div>
        <Tooltip title={"Download Logs"}>
            <IconButton onClick={onExportButtonClicked}>
                <Download />
            </IconButton>
        </Tooltip>
        <DataGrid
            sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
                ...relatedRowCSS,
            }}
            rows={sortedLogs}
            columns={columns}
            getRowId={getRowId}
            onRowClick={(params) => {
                setRelatedRows(findRelatedLog(params.row))
            }}
            autoHeight={true}
            getRowHeight={() => {
                return "auto"
            }}
            disableColumnMenu={true} />
    </div>
};

export default Logs;
