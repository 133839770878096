import React, {useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {CacheProvider} from "@emotion/react";
import {SnackbarProvider} from "notistack";
import {ConfirmProvider} from "material-ui-confirm";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";

import StartPage from "app/views/StartPage";
import IdentityPage from "app/views/IdentityPage";
import ChargePointPage from "app/views/ChargePointPage";
import AutoTestPage from "app/views/AutoTestPage";
import PageNotFoundPage from "app/views/PageNotFoundPage";

import createEmotionCache from "app/components/common/createEmotionCache";
import CpiThemeProvider from "lib/components/CpiThemeProvider";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const router = createBrowserRouter([
    {
        path: "/",
        children: [
            {
                path: "/",
                element: <StartPage/>
            },
            {
                path: "/charge-points",
                element: <Navigate to={"/"} replace/>
            },
            {
                path: "/identity",
                element: <IdentityPage/>
            },
            {
                path: "/charge-points/:identity",
                element: <ChargePointPage/>
            },
            {
                path: "/charge-points/:identity/auto",
                element: <AutoTestPage/>
            },
            {
                path: "/*",
                element: <PageNotFoundPage/>
            }
        ]
    }
]);

export function App() {

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false
                    }
                }
            })
    );

    return <CacheProvider value={clientSideEmotionCache}>
        <CpiThemeProvider>
            <QueryClientProvider client={queryClient}>
                <ConfirmProvider defaultOptions={{dialogProps: {sx: {".MuiDialog-paper": {padding: "0"}}}}}>
                    <SnackbarProvider anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
                        <RouterProvider router={router}/>
                    </SnackbarProvider>
                </ConfirmProvider>
            </QueryClientProvider>
        </CpiThemeProvider>
    </CacheProvider>
}

export default App;
