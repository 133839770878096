import {FieldArray, Form, Formik, useFormikContext} from "formik";

import {useContext} from "react";
import {ActionContext} from "app/components/action/ActionContext";
import useRouter from "app/hooks/useRouter";
import {GetConfiguration} from "app/components/action/model/Actions";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import {Stack} from "lib/components";
import TextField from "lib/components/TextField";
import Button from "lib/components/Button/Button";
import Box from "lib/components/Box/Box";
import useErrorHandler from "app/hooks/useErrorHandler";
import OcppApiService from "app/services/OcppApiService";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import {ActionType} from "app/components/action/model/ActionType";

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const {values, handleChange, isValid} = useFormikContext<GetConfiguration>();

    return <Form>
        <DialogHeader>Get configuration</DialogHeader>
        <DialogContent>
            <FieldArray
                name="keys"
                render={(arrayHelpers) => (
                    <Stack direction="column" spacing="3rem">
                        {values.keys.map((key, index) => (
                            <Stack direction="row" spacing="1rem" key={index}>
                                <TextField
                                    fullWidth
                                    id={`keys.${index}`}
                                    name={`keys.${index}`}
                                    value={values.keys[index]}
                                    onChange={handleChange}
                                />
                                <Button
                                    type="button"
                                    variant="secondary"
                                    onClick={() => arrayHelpers.remove(index)}
                                    icon="Minus"
                                />
                            </Stack>
                        ))}

                        <Box>
                            <Button
                                variant="secondary"
                                onClick={() => arrayHelpers.push("")}
                                icon="Plus"/>
                        </Box>
                    </Stack>
                )}
            />
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
};

const GetConfigurationForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: GetConfiguration) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.GetConfiguration, params.identity, request);
            await OcppApiService.getConfiguration(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                keys: [],
            }}
            onSubmit={handleSubmit}>
            <InnerForm/>
        </Formik>
    );
};

export default GetConfigurationForm;
