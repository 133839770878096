import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import {CircularProgress, StepContent, styled} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {ErrorOutline} from "@mui/icons-material";
import ChargePointTestDTO from "./model/ChargePointTestDTO";
import {TestResult} from "./model/ChargePointTestStepDTO";
import TestStepDTO from "./model/TestStepDTO";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';


const StepperSx = {
    "& .MuiStepContent-root": {
        marginLeft: "23px" // To position the content part of the line further to the right
    },
    "& .MuiStepConnector-line": {
        marginLeft: "11px" // To position the connector part of the line further to the right
    }
};

const ColorLibStepIconRoot = styled('div')<{
    props: ColorlibStepIconProps
}>(({theme, props}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(props.result == TestResult.Pending && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(props.result == TestResult.Success && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

interface ColorlibStepIconProps {
    testStep: TestStepDTO;
    result?: TestResult;
}

function ColorlibStepIcon(
    props: ColorlibStepIconProps
) {

    const determineIcon = (result?: TestResult) => {
        switch (result) {
            case TestResult.Pending:
                return <CircularProgress/>;
            case TestResult.Failure:
                return <ErrorOutline/>;
            case TestResult.Success:
                return <CheckIcon/>;
            default:
                return <SettingsEthernetIcon/>;
        }
    };


    return (
        <ColorLibStepIconRoot props={props}>
            {determineIcon(props.result)}
        </ColorLibStepIconRoot>
    );
}

function enhanceTestStepWithStatus(
    testStep: TestStepDTO,
    test: ChargePointTestDTO
): ColorlibStepIconProps {
    const foundStep = test.steps.find(s => s.name === testStep.title);
    return {
        testStep: testStep,
        result: foundStep?.result,
    };
}

interface TestStepperProps {
    testSteps: TestStepDTO[]
    test: ChargePointTestDTO;
}

function TestStepper(
    props: TestStepperProps
) {

    const isTestDone = (test: ChargePointTestDTO | undefined): boolean => {
        return test?.steps?.some(step => step.name === "Done") ?? false;
    };

    const activeStepper = () => {
        // Return false if the test is not started or if it's done.
        return props.test && !isTestDone(props.test) ? undefined : false;
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{width: '100%'}}>
                <Stepper
                    activeStep={props.test.steps.length - 1}
                    orientation="vertical"
                    sx={StepperSx}
                >
                    {props.testSteps
                        .filter(step => step.title !== "Done") // Filter out the "Done" step
                        .map((stepInfo) => {
                            const enhancedStepInfo = enhanceTestStepWithStatus(stepInfo, props.test);

                            return (
                                <Step key={stepInfo.title}
                                      active={activeStepper()}>
                                    <StepLabel
                                        StepIconComponent={() => ColorlibStepIcon(enhancedStepInfo)}
                                    >
                                        {stepInfo.title}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{stepInfo.subtitle}</Typography>
                                    </StepContent>
                                </Step>
                            );
                        })}
                </Stepper>
            </Box>
        </Box>
    );
}

export default TestStepper;
